import React, { useEffect, useState } from "react"
import { useSharedState } from "../SharedState/PageSharedState"

var classNames = require("classnames")
const cursorElement = props => {
  var comCursorCircleClass = { cursorText: true }
  comCursorCircleClass = props.comCursorCircleClass
  var comCursorClass = { cursorText: true }
  comCursorClass = props.comCursorClass
  comCursorClass.testing = "testing"
  //comCursorClass.cursorText = true
  var comCursorCircleClassString = "cursor"
  var comCursorClassString = "cursor"
  var comCursorText = ""

  const [state, setState] = useSharedState()
  // const overContainer = () => {
  //   setState({CursorOverContainer: true });
  // };
  // const notOverContainer = () => {
  //   setState({CursorOverContainer: false });
  // };

  // useState (
  //   requestAnimationFrame(() => {
  //     if (state.CursorOverContainer == true) {
  //         // setState({CursorOverContainer: true })
  //         console.log("TRUE: state.CursorOverContainer has state("+ comCursorText + ")\n" + "comCursorCircleClassString has state("+comCursorCircleClassString+")")
  //     } else if (state.CursorOverContainer == false) {
  //       comCursorClass.cursorText = false
  //       comCursorClass.testing = false
  //       comCursorText = ""
  //       //comCursorClass.cursorText = false
  //       // setState({CursorOverContainer: false })
  //       console.log("FALSE: comCursorClass.cursorText has state("+ comCursorText + ")\n" + "comCursorCircleClassString has state("+comCursorCircleClassString+")")
  //     }
  //   })
  // )

  useEffect(() => {
    // comCursorCircleClassString = classNames(comCursorCircleClass)
    // console.log("comCursorClass" + comCursorClass)
    // comCursorClassString = classNames(comCursorClass)
    // console.log("comCursorClassString" + comCursorClassString)

    return undefined
  }, [])

  return (
    <>
      <div
        className={comCursorCircleClassString}
        style={props.circleStyle}
        id="cursorCircle"
      ></div>
      <div
        className={comCursorClassString + " " + state.cursorOverContainerClass}
        style={props.cursorStyle}
        id="cursor"
      >
        {state.cursorOverContainerText}
      </div>
    </>
  )
}

export default cursorElement
