import React from "react"
import PropTypes from "prop-types"
var classNames = require("classnames")

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from  '@fortawesome/pro-light-svg-icons/faTimes'

class SearchInput extends React.Component {
  constructor(props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
  }

  componentDidMount(){
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount(){
    document.removeEventListener("keydown", this.escFunction, false);
  }

  escFunction(event){
    if(event.keyCode === 27 && this.props.isOpen && !this.props.isOpening && !this.props.isClosing) {
      this.props.toggleSearch();
    }
  }

  render() {

    return(
      <input
        className="input is-large"
        type="search"
        placeholder={this.props.language === "es" ? "¿Qué podemos ayudarlo a encontrar?" : "What can we help you find?"}
        onChange={this.props.handleSearchInputChange}
        autoFocus="true"
      />
    )    
  }

}

SearchInput.propTypes = {
  isOpen: PropTypes.bool,
  isOpening: PropTypes.bool,
  isClosing: PropTypes.bool,
  handleSearchInputChange: PropTypes.func,
  toggleSearch: PropTypes.func,
  language: PropTypes.string
}

class Search extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {}

  render() {
    var searchClass = classNames({
      section: true,
      "search-container": true,
      "opens-left": this.props.opensLeft,
      "search-container--open": this.props.isOpen,
      "search-container--closing": this.props.isClosing,
    })

    return (
      <>
        <form className={searchClass} onSubmit={this.props.handleSearchSubmit}>
          <div className="control has-icons-right">
            {this.props.isOpen && <SearchInput {...this.props} />}
            <span className="icon is-small is-right search-icon-text">
              <span>
                {this.props.language === "es" && <div>Presione <strong>RETURN</strong> para buscar</div>}
                {this.props.language === "en" && <div>Press <strong>RETURN</strong> to search</div>}
              </span>
            </span>
            <span className="icon is-small is-right search-icon">
              {/* <i
                className="fal fa-times column"
                onClick={this.props.toggleSearch}
              ></i> */}
              <FontAwesomeIcon icon={faTimes} onClick={this.props.toggleSearch} />
            </span>
          </div>
        </form>
        {/* <ul className={suggestionClass}>{suggestionItems}</ul> */}
      </>
    )
  }
}
Search.propTypes = {
  isOpen: PropTypes.bool,
  isClosing: PropTypes.bool,
  handleSearchSubmit: PropTypes.func,
  handleSearchInputChange: PropTypes.func,
  toggleSearch: PropTypes.func,
  suggestion: PropTypes.string,
  suggestions: PropTypes.array,
  language: PropTypes.string
}
export default Search
