import { Link, navigateTo, navigate } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { isMobileOnly } from 'react-device-detect';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/pro-light-svg-icons/faEnvelope"

var classNames = require("classnames")

function FloatingActionButton(props) {
  let fabClasses = classNames("sticky-fab", props.layoutClass, {
    "hide-fab-mobile": props.hideOnMobile && isMobileOnly
  })
  return (
    <a
      id="stickyFab"
      href={
        props.language === "es"
          ? "/es/comuniquese-con-nosotros/"
          : "/contact-us/"
      }
      className={fabClasses + " fab-language-" + props.language}
    >
      <div className="fab-container">
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
    </a>
  )
}

FloatingActionButton.propTypes = {}

export default FloatingActionButton
