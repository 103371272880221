import { useState } from 'react';
import { createContainer } from 'react-tracked';

const initialState = {
  cursorOverContainer: false,
  cursorOverContainerClass: "",
  cursorOverContainerText: "",
  browserStatus: true,
  browserName: ""
};
const useMyState = () => useState(initialState);
export const {
  Provider: SharedStateProvider,
  useTracked: useSharedState,
} = createContainer(useMyState);