import React from "react"
import CursorElement from "./CursorElement"
import PropTypes from 'prop-types';

var classNames = require("classnames")

const pressedColor ="#5aba4d"
const cursorColor ="#0050bb"
const circleColor ="#0050bb"

export default class ComCursor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fullCursorDisplayed: "none",
      circleDelay: 0,
      textColor: "#006D15",
      pressedColor: pressedColor,
      cursorColor: cursorColor,
      circleColor: circleColor,
      pressed: false,
      play: false,
      playText: "Play",
      pause: false,
      playPause: "Pause",
      firstMove: true,
      cursorOverContainer: this.props.cursorOverContainer
    }
    this.changeCursor = this.changeCursor.bind(this)
  }

  componentDidMount() {
    var that = this

    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(f) {
        setTimeout(f, 1000 / 60)
      }

    const body = document.querySelector("body")

    body.addEventListener("DOMContentLoaded", e => {
      document.getElementById(
        "cursor"
      ).style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
      document.getElementById(
        "cursorCircle"
      ).style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
      //document.getElementById("cursor").classList.remove("displayed");
      //document.getElementById("cursorCircle").classList.remove("displayed");
    })

    body.addEventListener("mousemove", e => {
      requestAnimationFrame(() => {
        if (this.props.pageLoaded) {
          if (document.getElementById("cursor")) {
            document.getElementById(
              "cursor"
            ).style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
            document.getElementById("cursor").classList.add("displayed")

            if (!this.props.noLazyFollow) {
              document.getElementById(
                "cursorCircle"
              ).style.transform = `translate(${e.clientX}px, ${e.clientY}px)`
              document.getElementById("cursorCircle").classList.add("displayed")
              if (this.state.firstMove) {
                that.setState({ firstMove: false })
              } else if (!this.state.firstMove && that.state.pressed == false) {
                that.setState({ circleDelay: 17 })
              }
            }
          }
        }
      })
    })
    body.addEventListener("mousedown", () => {
      requestAnimationFrame(() => {
        if (that.state.pageLoaded) {
          that.setState({
            pressed: true,
            circleColor: pressedColor,
            cursorColor: pressedColor,
          })
          that.setState({ circleDelay: 0 })
        }
      })
    })
    body.addEventListener("mouseup", () => {
      requestAnimationFrame(() => {
        if (that.state.pageLoaded) {
          that.setState({
            pressed: false,
            circleColor: circleColor,
            cursorColor: cursorColor,
          })
          //that.setState({ circleDelay: 17})
        }
      })
    })
  }

  changeCursor() {}

  render() {

    var comCursorClass = {
      cursor: true,
      cursorText: false,
    }

    var comCursorCircleClass = {
      cursor: true,
    }

    var cursorStyle = {
      backgroundColor: this.state.cursorColor,
    }

    var circleStyle = {
      border: "1px solid" + this.state.circleColor,
      transition: "transform ." + this.state.circleDelay + "s ease",
      display: this.props.noOuter ? "none" : ""
    }

    // var logo = <Logo />
    // if (this.state.logoChangesOnScroll) {
    //   if (!this.state.atTopOfPage) {
    //     logo = <LogoWhite />
    //   }
    // }

    return (
      <CursorElement
        comCursorText={this.state.playText}
        comCursorClass={comCursorClass}
        comCursorCircleClass={comCursorCircleClass}
        cursorStyle={cursorStyle}
        circleStyle={circleStyle}
      />
    )
  }
}

ComCursor.propTypes = {
  cursorOverContainer: PropTypes.bool,
  pageLoaded: PropTypes.bool,
  noLazyFollow: PropTypes.bool,
  noOuter: PropTypes.bool
}