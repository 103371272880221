import React, { PureComponent } from "react"

export function getScrollData (that) {
    let scrollHeight = that.state.scrollHeight
    let scrollOfset = that.state.scrollOfset
    let scrollPercentage = that.state.scrollPercentage
    scrollHeight =
    Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    ) - window.innerHeight
  
    scrollOfset = Math.round(window.pageYOffset)
    scrollPercentage = Math.round((scrollOfset / scrollHeight) * 100)
    that.setState({
    scrollPercentage: scrollPercentage,
    scrollHeight: scrollHeight,
    scrollOfset: scrollOfset,
    didScroll: true,
    })
   }

export function addScrollListener (that) {
    window.addEventListener('scroll', getScrollData(that), false);
}

export default class scrollData extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      scrollHeight: 0,
      scrollOfset: 0,
      scrollPercentage: 0,
      didScroll: false,
      scrollVal: 10
    }

    this.handleScrollAnimation = this.handleScrollAnimation.bind(this)
  }

 

  handleScrollAnimation(scrolltop) {
    var that = this
    that.setState({
      didScroll: true,
      scrollVal: 0,
    })
    // Create cross browser requestAnimationFrame method:
    window.requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function(f) {
        setTimeout(f, 1000 / 60)
      }
    var cutoutText = document.getElementById("cutoutText")
    var underText = document.getElementById("underText")
    var homeCarousel = document.getElementById("homeCar")
    var stickyFab = document.getElementById("stickyFab")
    var sizzleTheaterButton = document.getElementById("sizzleTheaterButton")
    var comFooter = document.getElementById("main-homepage");
    let scrollHeight = this.state.scrollHeight
    let scrollOfset = this.state.scrollOfset
    let scrollPercentage = this.state.scrollPercentage
    function parallaxScroll(selectedElement) {
      if (!scrolltop) {
        scrolltop = window.pageYOffset // get number of pixels document has scrolled vertically
      }
      if (scrolltop > 1) {
        //window.scrollTo(0, window.pageYOffset + that.state.scrollVal)
        setTimeout(function() {
          underText.style.opacity = "1";
          sizzleTheaterButton.style.opacity = "1";

          homeCarousel.style.display = "block";
        }, 200)
        setTimeout(function() {
          stickyFab.style.opacity = "1";
          homeCarousel.style.display = "block";
          comFooter.style.display = "block";
        }, 1500)

        selectedElement.style.top = -100 + "vh"
      }
      // else {
      //   selectedElement.style.top = 0 + "vh"
      // }
    }

    function animateOnScroll() {
      cutoutText = document.getElementById("cutoutText")
      requestAnimationFrame(() => {
        if (cutoutText){
          parallaxScroll(cutoutText)
        }
      }) // call parallaxScroll() on next available screen paint
      getScrollData()

      return scrollPercentage
    }

    animateOnScroll()
  }

  componentDidMount() {
    document.documentElement.classList.add("beforeWheel")
    window.addEventListener('DOMMouseScroll', preventDefault, false);
    document.addEventListener('wheel', preventDefault, {passive: false}); // Disable scrolling in Chrome
    window.onwheel = preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault; // older browsers, IE
    window.ontouchmove  = preventDefault; // mobile
    document.onkeydown  = preventDefaultForScrollKeys; // keys

    var keys = {37: 1, 38: 1, 39: 1, 40: 1};
    var that = this

    function preventDefault(e) {
      e = e || window.event;
      if (e.preventDefault)
          e.preventDefault();
      e.returnValue = false;  
    }
    
    function preventDefaultForScrollKeys(e) {
        if (keys[e.keyCode]) {
            preventDefault(e);
            return false;
        }
    }

    function scrollOverride() {
      document.documentElement.classList.remove("beforeWheel")
      that.handleScrollAnimation(2)
      window.removeEventListener("wheel", wheelEvent)
      window.removeEventListener("touchstart", wheelEvent)
      window.removeEventListener('DOMMouseScroll', wheelEvent);
      window.removeEventListener('onwheel', wheelEvent);
      window.removeEventListener('onmousewheel', wheelEvent);
      window.removeEventListener('ontouchmove', wheelEvent);
      window.removeEventListener('keydown', wheelEvent);
      document.removeEventListener('onkeydown', wheelEvent);
      clearTimeout(forceScroll);
      setTimeout(() => {
        window.removeEventListener('DOMMouseScroll', preventDefault, false);
        document.removeEventListener('wheel', preventDefault, {passive: false}); // Enable scrolling in Chrome
        window.onwheel = null;
        window.onmousewheel = document.onmousewheel = null;
        window.ontouchmove = null;
        document.onkeydown = null;
      }, 1500);
    }
    
    const wheelEvent = e => {
      const delta = Math.sign(e.deltaY);
      if (delta != -1) {
        scrollOverride()
      }
    }

    var forceScroll = setTimeout(() => {
      scrollOverride()
    }, 7000);

    window.addEventListener("wheel", wheelEvent)
    window.addEventListener("touchstart", wheelEvent)
    window.addEventListener('DOMMouseScroll', wheelEvent);
    window.addEventListener('onwheel', wheelEvent);
    window.addEventListener('onmousewheel', wheelEvent);
    window.addEventListener('ontouchmove', wheelEvent);
    document.addEventListener('keydown', wheelEvent);
    window.addEventListener('onkeydown', wheelEvent);
    
    
    // if (this.state.didScroll == false) {
    //   setTimeout(function() {
    //     //window.scrollTo(0, window.pageYOffset + that.state.scrollVal)
    //     //that.handleScrollAnimation()
    //   }, 7000)
    // }
  }

  componentWillUnmount() {
  }

  render() {

    return (
      <>
      </>
    )
  }
}
